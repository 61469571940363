import $ from 'jquery';
import 'owl.carousel';
import './jquery.selectric.min.js';
import validate from "jquery-validation";
import sbjs from 'sourcebuster';

$(document).ready(function () {
    function sendGoalToYandex(counterId, goalId) {

    }
    var spinnerData = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Отправка...';
    const YA_CLID = 53238004;
    //init sbjs
    sbjs.init();
    $('.validate-form').each(function () {
        $(this).validate({
            rules: {
                email: {
                    required: true,
                    email: true
                },
                theme: {
                    required: true
                },
            },
            messages: {
                email: {
                    required: 'Поле e-mail обязательно для заполнения',
                    email: 'Введите корректный e-mail'
                },
                theme: {
                    required: 'Поле "Тема работы" обязательно для заполнения'
                },
            },
            submitHandler: function (form) {
                // some other code
                // maybe disabling submit button
                // then:
                var submitBtn = $(form).find('button[type="submit"]');
                var submitBtnText = submitBtn.text();
                deactivateBtn(submitBtn);
                $.ajax({
                    type: 'POST',
                    url: '/wp-json/crm/v1/createUser/',
                    data: $(form).serialize(),
                    success: function (res) {
                        var url;
                        ym(YA_CLID, 'reachGoal', 'ORDER');
                        if (res.authlink) {
                            url = new URL(res.authlink + '/firstOrder/');
                        } else {
                            url = new URL('https://studservis-lk.ru/orders/edit/');
                        }
                        res.selected_work ?
                            url.searchParams.set('tpw', res.selected_work) : null;
                        res.theme ?
                            url.searchParams.set('theme', res.theme) : null;
                        window.location.href = url.toString();
                    },
                    error: function () {
                        activateBtn(submitBtn, submitBtnText);
                        alert('Ошибка отправки.');
                    }
                });

            }
        })
    });
    $("#calc_step").on('click', function () {
        $("#calc_email").show();
        $(this).hide();
        $("#calc_result").show();
        $("#calc_submit").show();

    });
    $('select').selectric({
        maxHeight: 200,
        onChange: function (element) {
            $(element).change();
            if ($(element).hasClass('pop_calc_select')) {
                $("#calc_sale > span, #calc_price > span").text('-');
                var job = $(element).find('option:selected').attr('data-job');
                $("#calc_step").attr('disabled', false);
                console.log(job)
                if (typeof job !== "undefined") {
                    $.ajax({
                        type: 'POST',
                        url: '/wp-json/crm/v1/getJobInfo/',
                        data: {
                            tag: job
                        },
                        success: function (res) {
                            console.log(res);
                            console.log(typeof res.fields);
                            console.log((res.fields && typeof res.fields !== "undefined" && res.fields.tpw_price && typeof res.fields.tpw_price !== "undefined"));
                            if (typeof res.fields !== "undefined" && res.fields.tpw_price) {
                                var sale = (parseInt(res.fields.tpw_price) * 0.3);
                                sale = Math.round(sale / 100) * 100;
                                $("#calc_price > span").text(parseInt(res.fields.tpw_price));
                                $("#calc_sale > span").text(parseInt(sale));
                            }
                        },
                        error: function (xhr, status, error) {
                            var err = JSON.parse(xhr.responseText);
                            console.log(err);
                        }
                    });

                }

            }
        },
    });

    function validateEmail(email) {
        var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return re.test(String(email).toLowerCase());
    }

    var $topslider = $('.js-carousel');

    $topslider.owlCarousel({
        loop: false,
        margin: 0,
        autoplay: false,
        nav: true,
        dots: true,
        responsive: {
            0: {
                items: 1,
                slideBy: 1
            },
            768: {
                items: 2,
                slideBy: 2
            },
            1200: {
                items: 3,
                slideBy: 3
            }
        }
    });

    $('.js-top-slider-dot').on('click', function () {
        var index = $(this).index();

        $topslider.trigger('to.owl.carousel', [index, 300]);
    });

    const themeInput = $('#top-panel-form-theme');
    const emailBox = $('.js-top-panel-form-emailcontainer');
    const emailInput = $('#top-panel-form-email');
    const emailInputFooter = $('.registration-box__form-input');
    const btn = $('.js-top-panel-form-btn');
    const btnFooter = $('.registration-box__form-btn');

    function deactivateBtn (btn) {
        btn.attr('disabled', true);
        btn.html(spinnerData);
    }
    function activateBtn (btn, btnText) {
        btn.attr('disabled', false);
        btn.text(btnText);
    }
    btnFooter.on('click', function (e) {
        e.preventDefault();
        const valid = validateEmail($(emailInputFooter).val());
        const btnText = $(this).attr('text');
        if (!valid) {
            emailInputFooter.addClass('error');
            emailInputFooter.parent().find('.error-text').removeClass('hidden');
            return false;
        }
        if (valid) {
            deactivateBtn($(this));
            $.ajax({
                type: 'POST',
                url: '/wp-json/crm/v1/createUser/',
                data: $(this).parents('form:first').serialize(),
                success: function (res) {
                    var selectedWork;
                    ym(YA_CLID,'reachGoal','ORDER')
                    selectedWork = (typeof res.selected_work !== "undefined" && res.selected_work) ? '?tpw=' + res.selected_work : '';
                    if (typeof res.authlink !== "undefined") {
                        window.location.href = res.authlink + '/firstOrder/' + selectedWork;
                    } else {
                        window.location.href = 'https://studservis-lk.ru/orders/edit/' + selectedWork;
                    }
                },
                error: function () {
                    activateBtn($(this), btnText)
                    alert('Ошибка отправки.');
                }
            });
        }

    });
    if (!!themeInput.length) {
        btn.on('click', function (e) {
            e.preventDefault();
            const valid = validateEmail($(emailInput).val());
            const btnText = $(this).attr('text');

            if (themeInput.val().length && emailBox.hasClass('top-panel__form-hidden')) {
                $('.top-panel-bg').addClass('active');
                emailBox.removeClass('top-panel__form-hidden');

                return false;
            }

            if (!themeInput.val().length) {
                themeInput.addClass('error');
                themeInput.parent().find('.error-text').removeClass('hidden');

                return false;
            }

            if (!valid) {
                emailInput.addClass('error');
                emailInput.parent().find('.error-text').removeClass('hidden');
                return false;
            }
            if (valid) {
                deactivateBtn($(this))
                $.ajax({
                    type: 'POST',
                    url: '/wp-json/crm/v1/createOrder/',
                    data: $(this).parents('form:first').serialize(),
                    success: function (res) {
                        ym(YA_CLID,'reachGoal','ORDER')
                        if (typeof res.link !== 'undefined') {
                            window.location.href = res.link + '/newOrderId=' + res.order_id + '/';
                        } else {
                            window.location.href = 'https://studservis-lk.ru/orders/edit/';
                        }
                    },
                    error: function () {
                        activateBtn($(this), btnText)
                        alert('Ошибка отправки.');
                    }
                });
            }
        });

        themeInput.on('keyup', function () {
            if (themeInput.hasClass('error')) {
                themeInput.removeClass('error');
                themeInput.parent().find('.error-text').addClass('hidden');
            }
        });

        emailInput.on('keyup', function () {
            if (emailInput.hasClass('error')) {
                emailInput.removeClass('error');
                emailInput.parent().find('.error-text').addClass('hidden');
            }
        });
    }

    if (!themeInput.length) {
        emailInput.on('keyup', function () {
            const valid = validateEmail($(this).val());

            if (emailInput.hasClass('error')) {
                emailInput.removeClass('error');
                emailInput.parent().find('.error-text').addClass('hidden');
            }

            if (!!valid) $('.top-panel-bg').addClass('active');
        });

        btn.on('click', function (e) {
            e.preventDefault();
            const valid = validateEmail($(emailInput).val());
            const btnText = $(this).attr('text');

            if (!valid) {
                emailInput.addClass('error');
                emailInput.parent().find('.error-text').removeClass('hidden');
                return false;
            }
            if (valid) {
                deactivateBtn($(this))
                $.ajax({
                    type: 'POST',
                    url: '/wp-json/crm/v1/createUser/',
                    data: $(this).parents('form:first').serialize(),
                    success: function (res) {
                        var selectedWork;
                        ym(YA_CLID,'reachGoal','ORDER')
                        selectedWork = (typeof res.selected_work !== "undefined" && res.selected_work) ? '?tpw=' + res.selected_work : '';
                        if (typeof res.authlink !== "undefined") {
                            window.location.href = res.authlink + '/firstOrder/' + selectedWork;
                        } else {
                            window.location.href = 'https://studservis-lk.ru/orders/edit/' + selectedWork;
                        }
                    },
                    error: function () {
                        activateBtn($(this), btnText)
                        alert('Ошибка отправки.');
                    }
                });
            }
        });
    }

    var $popup = $('.popoup');

    $('.js-popup-open').on('click', function () {
        var id = false;
        id = $(this).attr('data-popup');
        openPopup(id);
    });

    $('.js-popup-close').on('click', function () {
        closePopup();
    });

    function openPopup(id = false) {
        var popup = $(".popoup");
        if (id) {
            popup = $("#" + id);
        }
        popup.addClass('open');
    }
    $(document).on('focus', 'input', function (e) {
        setCookie("showidget2", time(), {'path':'/'});
        setCookie("showidget", time(), {'path':'/'});
    })
    function closePopup() {
        $popup.removeClass('open');
    }
});